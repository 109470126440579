<template #notFoundContent>
  <span class="d-flex justify-content-center" style="font-weight:600;">Sorry, no matching options.</span>
</template>

<script>

export default {
  name: "SelectNotFoundSlot",
}
</script>
