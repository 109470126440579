const BASE_URL = process.env.VUE_APP_BASE_URL// "http://116.90.165.45:7080/db/bmkgsatu";

import axios from "axios";
import Helper from "@/api/helper";
import jwt from "@/auth/jwt/useJwt";

export default {

  submitNewPibal(data, path) {
    var url = BASE_URL + path;
    return axios.post(url, data, Helper.getConfig());
  },

  editPibal(data, path) {
    var url = BASE_URL + path;
    return axios.patch(url, data, Helper.getConfig());
  },

  getDataPibal(path) {
    var url = BASE_URL + path;
    return axios.get(url, Helper.getConfig());
  }
};
