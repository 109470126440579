// const BASE_URL = "http://116.90.165.45:7080/db/bmkgsatu";
const BASE_URL = process.env.VUE_APP_BASE_URL;

export default {
    getMonthCode(month) {
        var MountStore = ["JAN", "FEB", "MAR", "APR", "MEI", "JUN", "JUL", "AUG", "SEP", "OKT", "NOV", "DES"];
        return MountStore[month];
    },

    getMonthText(month) {
        var MountStore = ["JANUARI", "FEBRUARI", "MARET", "APRIL", "MEI", "JUNI", "JULI", "AGUSTUS", "SEPTEMBER", "OKTOBER", "NOVEMBER", "DESEMBER"];
        return MountStore[month];
    },

    URL_WMO_API(PARAM) {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            return BASE_URL + "/@aggregation_query?type_name=BmkgStation" + PARAM + "&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id&_size=10000";
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }
            return BASE_URL + "/@aggregation_multiquery?type_name=BmkgStation" + PARAM + "&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id&_size=10000&station_id=" + ids + "&station_id__gte=1&station_id__lte=30000";
        }

        // return BASE_URL+"/@aggregation_query?type_name=BmkgStation"+PARAM+"&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id&_size=10000";
    },
    URL_WMO_API_INTEGRASI() {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            return BASE_URL + "/@aggregation_query?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id&_size=10000";
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }
            return BASE_URL + "/@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id&_size=10000&station_id=" + ids + "&station_id__gte=1&station_id__lte=30000";
        }

        // return BASE_URL+"/@aggregation_query?type_name=BmkgStation"+PARAM+"&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id&_size=10000";
    },

    URL_STATION_API(PARAM) {
        return (
            BASE_URL +
            "/@aggregation_query?type_name=BmkgStation" +
            PARAM +
            "&_metadata=station_id,station_name,station_name,station_wmo_id,station_elevation,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,kecamatan_name,current_latitude,current_longitude&_size=100"
        );
    },

    URL_FILTER_STATION_API(PARAM) {
        return (
            BASE_URL +
            "/@aggregation_query?type_name=BmkgStation" +
            PARAM +
            "&_metadata=station_id,station_name,station_name,station_wmo_id,station_elevation,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,kecamatan_name,current_latitude,current_longitude&_size=10000"
        );
    },

    URL_ELEMENT_STATION(PARAM) {
        return BASE_URL + PARAM;
    },

    URL_STATION_ALL_API(PARAM) {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            return BASE_URL + "/@aggregation_query?type_name=BmkgStation" + PARAM + "&_metadata=station_id,alias_station_id,station_name,station_wmo_id&_size=100000";
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }
            return BASE_URL + "/@aggregation_multiquery?type_name=BmkgStation" + PARAM + "&_metadata=station_id,alias_station_id,station_name,station_wmo_id&_size=100000&station_id=" + ids + "&station_id__gte=1&station_id__lte=30000";
        }

        // return BASE_URL+"/@aggregation_query?type_name=BmkgStation&_metadata=station_id,station_name,station_wmo_id&_size=100000";
    },

    URL_REPORT_48(WMO_ID, START_DATE, END_DATE) {
        return BASE_URL + "/@report_me48?station_wmo_id=" + WMO_ID + "&periode_start=" + START_DATE + "&periode_end=" + END_DATE;
    },

    URL_REPORT_45(WMO_ID, START_DATE, END_DATE) {
        return BASE_URL + "/@report_me45?station_wmo_id=" + WMO_ID + "&periode_start=" + START_DATE + "&periode_end=" + END_DATE;
    },

    URL_REPORT_METAR(WMO_ID, START_DATE, END_DATE) {
        return (
            BASE_URL +
            "/@report_metarspeci?station_wmo_id=" +
            WMO_ID +
            "&data_timestamp__gte=" +
            START_DATE +
            "&data_timestamp__lte=" +
            END_DATE +
            "&type_name=Metarspeci&_metadata=station_name,encoded_metar_speci,icao_id,station_id,station_wmo_id,data_timestamp"
        );
        //"/@report_metarspeci?station_wmo_id="+WMO_ID+"&data_timestamp__gte="++"&data_timestamp__lte="+END_DATE+"&type_name=MetarSpeci&_metadata=encoded_metar_speci,icao_id,station_id,station_name,station_wmo_id,data_timestamp";
    },

    URL_REPORT_FKIKLIM71(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_fklim71?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_IKLIM_MIKRO(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_iklim_mikro?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_SUHU_TANAH(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_suhu_tanah?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_KELEMBABAN_TANAH(STATION_ID, PERIODE, WAKTU) {
        return BASE_URL + "/@report_kelembaban_tanah?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_PIECE_PENGUAPAN(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_piche_penguapan?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_GUNBELLANI(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_gunbellani?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_LAMA_PENYINARAN(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_lama_penyinaran?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_THERMOHIGOGRAPH(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_thermohigograph?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_AKTINOGRAPH(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_aktinograph?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_BAROGRAPH(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_barograph?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_PSYCHROMETERASSMAN(STATION_ID, PERIODE, WAKTU) {
        return BASE_URL + "/@report_psychrometer_assman?station_id=" + STATION_ID + "&periode=" + PERIODE + "&waktu=" + WAKTU;
    },

    URL_PERAWANAN(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_form_perawanan?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_LYSIMETER(STATION_ID, PERIODE, WAKTU, TANAH) {
        return BASE_URL + "/@report_lysimeter?station_id=" + STATION_ID + "&periode=" + PERIODE + "&waktu=" + WAKTU + "&penampang=" + TANAH; //"/@report_kelembaban_tanah?station_id="+STATION_ID+"&periode="+PERIODE+"&waktu="+WAKTU;
    },

    URL_REPORT_FORMHUJAN(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_form_hujan?station_id=" + STATION_ID + "&periode=" + PERIODE; //+"&waktu="+WAKTU;
    },

    URL_REPORT_FORMHUJANHELMAN(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_hujan_hellman?station_id=" + STATION_ID + "&periode=" + PERIODE; //+"&waktu="+WAKTU;
    },

    URL_REPORT_OPPENGUAPAN(STATION_ID, PERIODE, WAKTU) {
        return BASE_URL + "/@report_op_penguapan?station_id=" + STATION_ID + "&periode=" + PERIODE + "&waktu=" + WAKTU;
    },

    URL_REPORT_AGM1A(STATION_ID, PERIODE, WAKTU) {
        return BASE_URL + "/@report_agm1a?station_id=" + STATION_ID + "&periode=" + PERIODE + "&waktu=" + WAKTU;
    },

    URL_REPORT_AGM1B(STATION_ID, PERIODE, WAKTU) {
        return BASE_URL + "/@report_agm1b?station_id=" + STATION_ID + "&periode=" + PERIODE + "&waktu=" + WAKTU;
    },

    URL_REPORT_WXREV(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_wxrev?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REGION() {
        return BASE_URL + "/@aggregation_query?type_name=BmkgRegion&_metadata=region_id,region_description,id";
    },

    URL_PROVINCE(REGION) {
        return BASE_URL + "/@aggregation_query?region_id=" + REGION + "&type_name=Propinsi&_metadata=region_id,region_description,propinsi_id,propinsi_name,propinsi_code,id,path&_size=10000";
    },

    URL_KABUPATEN(PROVINCE) {
        return BASE_URL + "/@aggregation_query?propinsi_id=" + PROVINCE + "&type_name=Kabupaten&_metadata=region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,id,path&_size=10000";
    },

    URL_VISUALITATION(PERIODE, RESOURCE, REGION, PROVINCE, KABUPATEN) {
        return BASE_URL + "/@visualization_raw_data?periode=" + PERIODE + "&resource=" + RESOURCE + "&region=" + REGION + "&province=" + PROVINCE + "&kabupaten=" + KABUPATEN;
    },

    URL_ELEMENT() {
        return BASE_URL + "/@setting_aelement";
    },

    URL_INGEST_LOG() {
        return (
            BASE_URL +
            "/@aggregation_query?type_name=LogIngest&_metadata=id,station_wmo_id,path,ingest_flag,ingest_separator,ingest_separator,ingest_fdih_type,ingest_start_date,ingest_end_date,ingest_form_type,ingest_error_message,ingest_description,ingest_total_raw,ingest_process_raw,ingest_progress,ingest_separator,station_name,ingest_tanggal_format&_size=10000"
        );
    },
    URL_INGEST_LOG_PROGRES() {
        return BASE_URL + "/@aggregation_query?type_name=LogIngest&_metadata=id,ingest_total_raw,ingest_process_raw,ingest_progress,ingest_flag&_size=10000";
    },

    URL_INGEST_LOG_PATH(WMO_ID) {
        return BASE_URL + "/@aggregation_query?type_name=BmkgStation&station_wmo_id=" + WMO_ID + "&_metadata=id,station_wmo_id,path";
    },

    URL_INGEST_LOG_PATH_CREATE(PATH) {
        return BASE_URL + PATH + "/klimatologi/pias/ingest_barograph";
    },

    URL_REPORT_ACS(STATION_ID, MONTH, START_YEARS, END_YEARS) {
        return BASE_URL + "/@report_acs?station_id=" + STATION_ID + "&months=" + MONTH + "&start_years=" + START_YEARS + "&end_years=" + END_YEARS;
    },

    URL_REPORT_FENOLOGI(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_fenologi?station_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    URL_REPORT_CLIMAT(STATION_ID, PERIODE) {
        return BASE_URL + "/@report_berita_climat?wmo_id=" + STATION_ID + "&periode=" + PERIODE;
    },

    DELETE_ARRAY(IS_DATA, IS_DELETE) {
        var DATA = IS_DATA;
        for (var i in IS_DELETE) {
            DATA.splice(DATA.indexOf(i), 1);
        }
        return DATA;
    },

    URL_LIST_MANAGE_USAGE() {
        return BASE_URL + "/settingapp/manageusage/@items_manageusage?include=manage_usage_id,manage_usage_name,manage_usage_status&page_size=100";
    },

    URL_ALL_PROVINCE() {
        return BASE_URL + "/@aggregation_query?type_name=Propinsi&_metadata=region_id,region_description,propinsi_id,propinsi_name,propinsi_code,id,path&_size=100";
    },

    URL_MULTI_QUERY(PARAMS) {
        return BASE_URL + "/@aggregation_multiquery?" + PARAMS;
    },
};
