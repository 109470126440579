import { helpers } from "vuelidate/lib/validators";
export function GC_WIND_DIR_DEG_DD(value) {
    return !helpers.req(value) || (value >= 0 && value <= 360) || value == 9999;
}
export function GC_WIND_SPEED_FF(value) {
    // return !helpers.req(value) || (value >= 0 && value <= 50) || value == 9999;
    return !helpers.req(value) || (value >= 0 && value <= 100) || value == 9999;
}
export function GC_VISIBILITY_VV(value) {
    return !helpers.req(value) || (value >= 0 && value <= 30) || value == 9999 || value === 8888;
}
export function GC_PRESENT_WEATHER_WW(value) {
    return !helpers.req(value) || (value >= 0 && value <= 99);
}
export function GC_PAST_WEATHER(value) {
    return !helpers.req(value) || (value >= 0 && value <= 9) || value == "/";
}
export function GC_PRESSURE_QFF_MB_DERIVED(value) {
    return !helpers.req(value) || (value >= 800 && value <= 1100) || value == 9999;
}
export function GC_PRESSURE_QFE_MB_DERIVED(value) {
    return !helpers.req(value) || (value >= 700 && value <= 1100) || value == 9999;
}
export function GC_TEMP_DRYBULB_C_TTTTTT(value) {
    return !helpers.req(value) || (value >= 5 && value <= 40) || value == 9999;
}
export function GC_TEMP_WETBULB_C(value) {
    return !helpers.req(value) || (value >= 5 && value <= 35) || value == 9999;
}
export function GC_TEMP_MAX_C_TXTXTX(value) {
    return !helpers.req(value) || (value >= 20 && value <= 45) || value == 9999;
}
export function GC_TEMP_MIN_C_TNTNTN(value) {
    return !helpers.req(value) || (value >= 5 && value <= 30) || value == 9999;
}
export function GC_RAINFALL_LAST_MM(value) {
    // return !helpers.req(value) || (value >= 0 && value <= 200) || value == 9999 || value == 8888;
    return !helpers.req(value) || (value >= 0 && value <= 350) || value == 9999 || value == 8888;
}
export function GC_CLOUD_LOW_BASE(value) {
    return !helpers.req(value) || (value >= 0 && value <= 2000) || value == 9999;
}
export function GC_CLOUD_MED_BASE(value) {
    return !helpers.req(value) || (value >= 600 && value <= 8999) || value == 9999;
}
export function GC_CLOUD_HIGH_BASE(value) {
    return !helpers.req(value) || (value >= 3000 && value <= 20000) || value == 9999;
}
export function GC_CLOUD_ELEVATION_ANGLE_EC(value) {
    return !helpers.req(value) || (value >= 0 && value <= 90);
}
export function GC_CLOUD_LOW_PEAK(value) {
    return !helpers.req(value) || (value >= 50 && value <= 20000) || value == 9999;
}
export function GC_EVAPORATION_24HOURS_MM_EEE(value) {
    return !helpers.req(value) || (value >= 0 && value <= 20) || value == 9999 || value == 8888;
}
export function GC_SOLAR_RAD_24H_JCM2_F24(value) {
    return !helpers.req(value) || (value >= 0 && value <= 3600) || value == 9999;
}
export function GC_SUNSHINE_H_SSS(value) {
    return !helpers.req(value) || (value >= 0 && value <= 13) || value == 9999;
}
// AN
export function GC_CLOUD_VERTICAL_VIS(value) {
    return !helpers.req(value) || (value >= 0 && value <= 5000) || value == 9999;
}

// Range Check
const RC = {
    wind_speed_ff: { min: 0, max: 30 },
    visibility_vv: { min: 0, max: 20 },
    rainfall_last_mm: { min: 0, max: 100 },
    cloud_low_base_1: { min: 100, max: 1500 },
    cloud_low_base_2: { min: 100, max: 1500 },
    cloud_low_base_3: { min: 100, max: 1500 },
    cloud_med_base_1: { min: 1000, max: 8000 },
    cloud_med_base_2: { min: 1000, max: 8000 },
    cloud_high_base_1: { min: 4500, max: 18000 },
    cloud_high_base_2: { min: 4500, max: 18000 },
    cloud_elevation_angle_ec: { min: 0, max: 90 },
    cloud_low_peak_1: { min: 100, max: 20000 },
    cloud_low_peak_2: { min: 100, max: 20000 },
    evaporation_24hours_mm_eee: { min: 0, max: 15 },
    solar_rad_24h_jcm2_f24: { min: 100, max: 2500 },
    sunshine_h_sss: { min: 0, max: 12 },
};

export function RC_SINOP(value, key) {
    let status = false;
    let message = "";
    let min = RC[key].min;
    let max = RC[key].max;

    if (value == "" || (value >= min && value <= max)) {
        status = true;
        message = "";
    } else {
        status = false;
        message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max;
    }

    return { status: status, message: message };
}

const GC = {
    input_data_wind_dir_deg_dd: { min: 0, max: 360, specialValue: [9999] },
    input_data_wind_speed_ff: { min: 0, max: 50, specialValue: [9999] },
    input_data_visibility_vv: { min: 0, max: 30, specialValue: [9999] },
    input_data_temp_drybulb_c_tttttt: { min: 5, max: 40, specialValue: [9999] },
    input_data_temp_wetbulb_c: { min: 5, max: 35, specialValue: [9999] },
    input_data_temp_min_c_tntntn: { min: 5, max: 30, specialValue: [9999] },
    input_data_temp_max_c_txtxtx: { min: 20, max: 45, specialValue: [9999] },

    input_data_rainfall_last_mm: { min: 0, max: 350, specialValue: [9999] },
    input_data_cloud_low_base_1: { min: 0, max: 2000, specialValue: [9999] },
    input_data_cloud_low_base_2: { min: 0, max: 2000, specialValue: [9999] },
    input_data_cloud_low_base_3: { min: 0, max: 2000, specialValue: [9999] },
    input_data_cloud_med_base_1: { min: 600, max: 8999, specialValue: [9999] },
    input_data_cloud_med_base_2: { min: 600, max: 8999, specialValue: [9999] },
    input_data_cloud_high_base_1: { min: 3000, max: 20000, specialValue: [9999] },
    input_data_cloud_high_base_2: { min: 3000, max: 20000, specialValue: [9999] },
    input_data_cloud_low_peak_1: { min: 50, max: 20000, specialValue: [9999] },
    input_data_cloud_low_peak_2: { min: 50, max: 20000, specialValue: [9999] },
    input_data_evaporation_24hours_mm_eee: { min: 0, max: 20, specialValue: [9999, 8888] },
    input_data_solar_rad_24h_jcm2_f24: { min: 0, max: 3600, specialValue: [9999] },
    input_data_sunshine_h_sss: { min: 0, max: 13, specialValue: [9999] },
    input_data_input_qff: { min: 800, max: 1100, specialValue: [9999] },
    input_data_input_qfe: { min: 700, max: 1100, specialValue: [9999] },

    // AN
    input_data_cloud_vertical_vis: { min: 0, max: 5000, specialValue: [9999] },
};
export function GC_SINOP(key) {
    let status = false;
    let message = "";
    let min = GC[key].min;
    let max = GC[key].max;

    status = false;
    message = "Gross Check - Batas Bawah " + min + ", Batas Atas " + max;
    return { status: status, message: message };
}
