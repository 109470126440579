<template>
    <div class="" style="font-size: 0.9rem;">
        <div class="row">
            <!-- left-->
            <div class="col-xl-5">
                <b-card class="bg-light-primary bodered">
                    <!-- <b-container fluid> -->
                    <div class="row">
                        <div class="col-xl-4 d-flex">
                            <label for="checkbox-cor" class="mr-2">COR <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Mengkoreksi laporan METAR/SPECI" /></label>
                            <b-form-checkbox id="checkbox-cor" v-model="general_data.status.COR" name="checkbox-cor" size="md" :disabled="disabled.status.COR"></b-form-checkbox>
                        </div>

                        <div class="col-xl-4 d-flex">
                            <label for="checkbox-nil" class="mr-2">NIL<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tidak ada laporan METAR" /> </label>
                            <b-form-checkbox id="checkbox-nil" v-model="general_data.status.NIL" name="checkbox-nil" size="md" :disabled="disabled.status.NIL"> </b-form-checkbox>
                        </div>

                        <div class="col-xl-4 d-flex">
                            <label for="checkbox-auto" class="mr-2">AUTO <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Mengunakan peralatan otomatis" /></label>
                            <b-form-checkbox id="checkbox-auto" v-model="general_data.status.AUTO" name="checkbox-auto" size="md" :disabled="disabled.status.AUTO"> </b-form-checkbox>
                        </div>
                    </div>
                    <!-- </b-container> -->
                </b-card>

                <b-card class="bodered">
                    <div class="row">
                        <div class="col-xl-12 font-weight-bolder mb-2 text-primary">
                            ANGIN
                        </div>
                    </div>
                    <div>
                        <b-row>
                            <b-col sm="3 pad2">
                                <label for="winds-direction">
                                    Arah Angin (derajat) <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Arah angin di bulatkan ke puluhan derajat terdekat" />
                                </label>
                            </b-col>
                            <b-col sm="4" class="pl-1">
                                <b-form-input
                                    id="winds-direction"
                                    class="text-center"
                                    :state="validateState($v.general_data.winds.direction)"
                                    v-model.number="$v.general_data.winds.direction.$model"
                                    type="number"
                                    v-on:keydown="onKeyCheck($event)"
                                    size="sm"
                                    :disabled="disabled.winds.direction"
                                    @keydown="formatInteger"
                                ></b-form-input>
                            </b-col>

                            <div class="col-xl-4 d-flex">
                                <label for="checkbox-vrb" class="mr-3"
                                    >VRB
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Variasi arah angin dengan kecapatan angin rata rata jika kec <4 knot maka variasi angin tidak perlu diinput"
                                /></label>
                                <b-form-checkbox id="checkbox-vrb" v-model.number="general_data.winds.VRB" name="checkbox-vrb" size="md" :disabled="disabled.winds.VRB"></b-form-checkbox>
                            </div>
                        </b-row>

                        <b-row class="mt-1">
                            <b-col sm="3 pad2">
                                <label for="wind_speed"> Kecepatan Angin (knot)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kecepatan angin dalam Knot" /> </label>
                            </b-col>
                            <b-col sm="4" class="pl-1">
                                <b-form-input
                                    class="text-center"
                                    :state="validateState($v.general_data.winds.speed)"
                                    v-model.number="$v.general_data.winds.speed.$model"
                                    type="number"
                                    v-on:keydown="onKeyCheck($event)"
                                    size="sm"
                                    :disabled="disabled.winds.speed"
                                    :class="{ 'text-center': true, rangecheck: RC.m_wind_speed.status }"
                                    id="wind_speed"
                                    @keyup="checkValidate($event, 'wind_speed')"
                                    @keydown="formatInteger"
                                ></b-form-input>
                                <b-tooltip v-if="RC.m_wind_speed.status" target="wind_speed" triggers="hover">
                                    {{ RC.m_wind_speed.message }}
                                </b-tooltip>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1">
                            <b-col sm="3 pad2">
                                <label for="wind_gust">
                                    Gust (Knot)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kecepatan angin meningkat 10 knot atau lebih dari kecepatan angin rata rata" />
                                </label>
                            </b-col>
                            <b-col sm="4" class="pl-1">
                                <b-form-input
                                    class="text-center"
                                    :state="validateState($v.general_data.winds.gust)"
                                    v-model.number="$v.general_data.winds.gust.$model"
                                    type="number"
                                    v-on:keydown="onKeyCheck($event)"
                                    size="sm"
                                    :disabled="disabled.winds.gust"
                                    :class="{ 'text-center': true, rangecheck: RC.m_wind_gust.status }"
                                    id="wind_gust"
                                    @keyup="checkValidate($event, 'wind_gust')"
                                    @keydown="formatInteger"
                                ></b-form-input>
                                <b-tooltip v-if="RC.m_wind_gust.status" target="wind_gust" triggers="hover">
                                    {{ RC.m_wind_gust.message }}
                                </b-tooltip>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1">
                            <b-col sm="3 mt-2 pad2">
                                <label> Variasi Angin </label>
                            </b-col>
                            <b-col sm="9">
                                <b-row>
                                    <b-col sm="6" class="pl-1">
                                        <label
                                            >Arah angin min.
                                            <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="perubahan arah angin antara 60-180 derajat dan kec angin rata rata >3 knot"
                                        /></label>
                                        <b-form-input
                                            id="winds-wd-dn"
                                            class="text-center"
                                            :state="validateState($v.general_data.winds.wd_dn)"
                                            v-model.number="$v.general_data.winds.wd_dn.$model"
                                            type="number"
                                            v-on:keydown="onKeyCheck($event)"
                                            size="sm"
                                            :disabled="disabled.winds.wd_dn"
                                            @keydown="formatInteger"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="6" class="pl-1">
                                        <label
                                            >Arah angin max.
                                            <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="perubahan arah angin antara 60-180 derajat dan kec angin rata rata >3 knot"
                                        /></label>
                                        <b-form-input
                                            id="winds-wd-dx"
                                            class="text-center"
                                            :state="validateState($v.general_data.winds.wd_dx)"
                                            v-model.number="$v.general_data.winds.wd_dx.$model"
                                            type="number"
                                            v-on:keydown="onKeyCheck($event)"
                                            size="sm"
                                            :disabled="disabled.winds.wd_dx"
                                            @keydown="formatInteger"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>

                <b-card no-body class="bodered bg-light-primary">
                    <b-row class="pl-1 pt-1">
                        <b-col sm="12" class="font-weight-bolder"> CAVOK </b-col>
                    </b-row>
                    <b-row class="mt-1 pl-1 pb-2">
                        <b-col sm="5">
                            <label for="checkbox-cavok" class=""
                                >Report CAVOK
                                <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips13" />
                                <!-- HTML tooltips -->
                                <b-tooltip target="tooltips13" triger="hover" placement="top">
                                    <dl class="text-left">
                                        <dt>Catatan</dt>
                                        <dd>- Jarak pandang mendatar 10 km atau lebih</dd>
                                        <dd>- Tidak ada cuaca bermakna</dd>
                                        <dd>- Tidak ada awan dibawah 1500 meter (5000 feet)</dd>
                                        <dd>- tidak ada awan CB</dd>
                                    </dl>
                                </b-tooltip>
                            </label>
                        </b-col>
                        <b-col sm="4" class="pl-1">
                            <b-form-checkbox id="checkbox-cavok" v-model="general_data.status.CAVOK" name="checkbox-cavok" size="md" :disabled="disabled.status.CAVOK"> </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card class="bodered">
                    <b-row class="mb-1">
                        <b-col sm="12" class="font-weight-bolder text-primary"> VISIBILITY </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col sm="4" style="padding-right: 2px;">
                            <label for="input-prevailing">Prevailling (m) <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jarak pandang mendatar " /></label>
                        </b-col>
                        <b-col sm="4" class="pl-1">
                            <b-form-input
                                id="input-prevailing"
                                class="text-center"
                                :state="validateState($v.general_data.visibility.prevailing)"
                                v-model.number="$v.general_data.visibility.prevailing.$model"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                size="sm"
                                :disabled="disabled.visibility.prevailing"
                                @keydown="formatInteger"
                            ></b-form-input>
                        </b-col>
                        <b-col sm="2">
                            <label for="checkbox-ndv" class="">NDV<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jarak pandang mendatar dengan alat" /></label>
                        </b-col>
                        <b-col sm="2" class="pl-1">
                            <b-form-checkbox id="checkbox-ndv" v-model="general_data.visibility.NDV" name="checkbox-ndv" size="md" :disabled="disabled.visibility.NDV"> </b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col sm="4">
                            <label class="">Minimum<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jarak pandang mendatar minimum" /></label>
                        </b-col>
                        <b-col sm="4" class="pl-1">
                            <b-form-input
                                id="input-minimum"
                                class="text-center"
                                :state="validateState($v.general_data.visibility.minimum)"
                                v-model.number="$v.general_data.visibility.minimum.$model"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                size="sm"
                                :disabled="disabled.visibility.minimum"
                                @keydown="formatInteger"
                            ></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col sm="4" style="padding-right: 3px;">
                            <label for="input-mvd" class="">Min Vis Direction <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Arah Jarak pandang mendatar minimum" /></label>
                        </b-col>
                        <b-col sm="4" class="pl-1">
                            <b-form-select id="input-mvd" v-model="general_data.visibility.min_vis" class="mb-0" size="sm" :disabled="disabled.visibility.min_vis">
                                <option value="">-</option>
                                <option v-for="(data, i) in options.visibility.min_vis" :key="i" :value="data">
                                    {{ data }}
                                </option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card no-body class="bodered">
                    <b-row class="mb-2 pl-1 pt-1">
                        <b-col sm="11" class="font-weight-bolder text-primary"> RVR </b-col>
                    </b-row>

                    <div class="table-responsive text-nowrap text-center" style="margin-bottom: 10px;">
                        <b-table striped hover fixed :items="[{}, ...general_data.rvr.items]" :fields="tables.rvr.fields" class="my-table table-condensed tbmetar" small>
                            <!-- row pertama buat input, jadi items dikasih object kosong di row pertama -->
                            <template v-slot:head(tendency)="data">
                                <span
                                    >{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltipshead1" />
                                    <b-tooltip target="tooltipshead1" triger="hover" placement="top">
                                        <dl class="text-left">
                                            <dt>Catatan</dt>
                                            <dd>Perubahan nilai RVR yang di sebabkan phenomena cuaca</dd>
                                            <dd>- U = RVR bertambah</dd>
                                            <dd>- D = RVR berkurang</dd>
                                            <dd>- N = RVR tidak ada perubahan</dd>
                                        </dl>
                                    </b-tooltip>
                                </span>
                            </template>

                            <template v-slot:head(ins_limit)="data">
                                <span
                                    >{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltipshead2" />
                                    <b-tooltip target="tooltipshead2" triger="hover" placement="top">
                                        <dl class="text-left">
                                            <dt>Catatan</dt>
                                            <dd>Harga RVR diluar kemampuan alat yang digunakan</dd>
                                            <dd>- P = harga RVR ekstrim Maksimum</dd>
                                            <dd>- M = harga RVR ekstrim Minimum</dd>
                                        </dl>
                                    </b-tooltip>
                                </span>
                            </template>

                            <template #cell(runway)="data">
                                <div v-if="data.index == 0" class="d-flex">
                                    <b-form-input
                                        id="rvr-runway"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        size="sm"
                                        :state="validateState($v.general_data.rvr.runway)"
                                        v-model="$v.general_data.rvr.runway.$model"
                                        :disabled="disabled.rvr.runway"
                                        @keyup="formatLimitChar($event, 2)"
                                        @keydown="formatInteger"
                                        class="text-center w-50"
                                    ></b-form-input>
                                    <b-form-select id="rvr-designator" class="ml-1 w-50" size="sm" v-model="general_data.rvr.designator" :disabled="disabled.rvr.runway">
                                        <option value="">-</option>
                                        <option v-for="(option, i) in options.designator" :key="i" :value="option">
                                            {{ option }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <div v-else-if="data.index - 1 === tables.rvr.edit_index">
                                    <b-form-input type="text" size="sm" v-model="general_data.rvr.items[tables.rvr.edit_index].runway" @keyup="formatLimitChar($event, 4)"></b-form-input>
                                </div>
                                <label v-else>{{ data.value }}</label>
                            </template>

                            <template #cell(vrvrvrvr)="data">
                                <b-form-input
                                    v-if="data.index == 0"
                                    id="rvr-vrvrvrvr"
                                    size="sm"
                                    v-model.number="general_data.rvr.vrvrvrvr"
                                    type="number"
                                    v-on:keydown="onKeyCheck($event)"
                                    :disabled="disabled.rvr.vrvrvrvr"
                                    @keydown="formatInteger"
                                    @keyup="formatLimitChar($event, 4)"
                                ></b-form-input>
                                <div v-else-if="data.index - 1 === tables.rvr.edit_index">
                                    <b-form-input type="number" v-on:keydown="onKeyCheck($event)" size="sm" v-model="general_data.rvr.items[tables.rvr.edit_index].vrvrvrvr" @keydown="formatInteger" @keyup="formatLimitChar($event, 4)"></b-form-input>
                                </div>
                                <label v-else>{{ data.value }}</label>
                            </template>

                            <template #cell(vr_vis_min)="data">
                                <b-form-select v-if="data.index == 0" id="rvr-vr-vis-min" class="mb-0" size="sm" v-model="general_data.rvr.vr_vis_min" :disabled="disabled.rvr.vr_vis_min">
                                    <option value="">-</option>
                                    <option v-for="(option, i) in options.rvr.vr_vis_min" :key="i" :value="option">
                                        {{ option }}
                                    </option>
                                </b-form-select>
                                <div v-else-if="data.index - 1 === tables.rvr.edit_index">
                                    <b-form-select class="mb-0" size="sm" v-model="general_data.rvr.items[tables.rvr.edit_index].vr_vis_min">
                                        <option value="">-</option>
                                        <option v-for="(option, i) in options.rvr.vr_vis_min" :key="i" :value="option">
                                            {{ option }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <label v-else>{{ data.value }}</label>
                            </template>

                            <template #cell(vr_vis_max)="data">
                                <b-form-select v-if="data.index == 0" id="rvr-vr-vis-max" class="mb-0" size="sm" v-model="general_data.rvr.vr_vis_max" :disabled="disabled.rvr.vr_vis_max">
                                    <option value="">-</option>
                                    <option v-for="(option, i) in options.rvr.vr_vis_max" :key="i" :value="option">
                                        {{ option }}
                                    </option>
                                </b-form-select>
                                <div v-else-if="data.index - 1 === tables.rvr.edit_index">
                                    <b-form-select class="mb-0" size="sm" v-model="general_data.rvr.items[tables.rvr.edit_index].vr_vis_max">
                                        <option value="">-</option>
                                        <option v-for="(option, i) in options.rvr.vr_vis_max" :key="i" :value="option">
                                            {{ option }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <label v-else>{{ data.value }}</label>
                            </template>

                            <template #cell(ins_limit)="data">
                                <b-form-select v-if="data.index == 0" id="rvr-ins-limit" class="mb-0" size="sm" v-model="general_data.rvr.ins_limit" :disabled="disabled.rvr.ins_limit">
                                    <option value="">-</option>
                                    <option v-for="option in options.rvr.ins_limit" :key="option.value" :value="option.value">
                                        {{ option.text }}
                                    </option>
                                </b-form-select>
                                <div v-else-if="data.index - 1 === tables.rvr.edit_index">
                                    <b-form-select class="mb-0" size="sm" v-model="general_data.rvr.items[tables.rvr.edit_index].ins_limit">
                                        <option value="">-</option>
                                        <option v-for="option in options.rvr.ins_limit" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <label v-else>{{ data.value }}</label>
                            </template>

                            <template #cell(tendency)="data">
                                <b-form-select v-if="data.index == 0" id="rvr-tendency" class="mb-0" size="sm" v-model="general_data.rvr.tendency" :disabled="disabled.rvr.tendency">
                                    <option value="">-</option>
                                    <option v-for="option in options.rvr.tendency" :key="option.value" :value="option.value">
                                        {{ option.text }}
                                    </option>
                                </b-form-select>
                                <div v-else-if="data.index - 1 === tables.rvr.edit_index">
                                    <b-form-select class="mb-0" size="sm" v-model="general_data.rvr.items[tables.rvr.edit_index].tendency">
                                        <option value="">-</option>
                                        <option v-for="option in options.rvr.tendency" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <label v-else>{{ data.value }}</label>
                            </template>

                            <template #cell(action)="data">
                                <b-button v-if="data.index == 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon rounded-circle" size="sm" @click="rvr_add()" :disabled="disabled.rvr.button">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>

                                <div v-else>
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon rounded-circle" size="sm" @click="rvr_edit(data.index - 1)">
                                        <feather-icon icon="SaveIcon" v-if="data.index - 1 === tables.rvr.edit_index" />
                                        <feather-icon icon="EditIcon" v-else />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon rounded-circle ml-1" size="sm" @click="rvr_delete(data.index - 1)">
                                        <feather-icon icon="Trash2Icon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </b-card>
            </div>

            <!-- right -->
            <div class="col-xl-7">
                <div class="row">
                    <div class="col-xl-12">
                        <b-card class="bodered">
                            <b-row>
                                <b-col sm="12" class="font-weight-bolder text-primary">
                                    CUACA SAAT PENGAMATAN <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="phenomena cuaca yang berlangsung di bandara pada saat pengamatan" />
                                </b-col>
                            </b-row>
                            <b-row class="wrap" ref="wrap" style="flex-wrap: nowrap;">
                                <b-col sm="4">
                                    <div>
                                        <label class="label-entry">Group 1</label>
                                    </div>
                                    <!-- button for popup -->
                                    <b-button
                                        variant="outline-secondary"
                                        :class="{ 'w-100 button-weather': true, 'is-invalid': validateState($v.general_data.weathers[0]) == false }"
                                        size="sm"
                                        :disabled="disabled.WEATHER"
                                        @click="openWeatherModal(0)"
                                    >
                                        {{ general_data.weathers[0] || "&nbsp;" }}
                                    </b-button>
                                </b-col>

                                <b-col sm="4">
                                    <div>
                                        <label class="label-entry">Group 2</label>
                                    </div>
                                    <!-- button for popup -->
                                    <b-button
                                        variant="outline-secondary"
                                        :class="{ 'w-100 button-weather': true, 'is-invalid': validateState($v.general_data.weathers[1]) == false }"
                                        size="sm"
                                        :disabled="disabled.WEATHER"
                                        @click="openWeatherModal(1)"
                                    >
                                        {{ general_data.weathers[1] || "&nbsp;" }}
                                    </b-button>
                                </b-col>

                                <b-col sm="4">
                                    <div>
                                        <label class="label-entry">Group 3</label>
                                    </div>
                                    <!-- button for popup -->
                                    <b-button
                                        variant="outline-secondary"
                                        :class="{ 'w-100 button-weather': true, 'is-invalid': validateState($v.general_data.weathers[2]) == false }"
                                        size="sm"
                                        :disabled="disabled.WEATHER"
                                        @click="openWeatherModal(2)"
                                    >
                                        {{ general_data.weathers[2] || "&nbsp;" }}
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col sm="12" class="font-weight-bolder text-primary">
                                    CUACA YANG LALU <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="phenomena cuaca yang sudah terjadi di bandara" />
                                </b-col>
                            </b-row>
                            <b-row class="wrap" ref="wrap" style="flex-wrap: nowrap;">
                                <b-col sm="4">
                                    <div>
                                        <label for="recent-w-1" class="label-entry">Group 1</label>
                                    </div>
                                    <b-form-select id="recent-w-1" v-model="general_data.recent_weathers[0]" size="sm" :disabled="disabled.WEATHER">
                                        <option value="">-</option>
                                        <option v-for="option in options.recent_weathers" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col sm="4">
                                    <div>
                                        <label for="recent-w-2" class="label-entry">Group 2</label>
                                    </div>
                                    <b-form-select id="recent-w-2" v-model="general_data.recent_weathers[1]" size="sm" :disabled="disabled.WEATHER">
                                        <option value="">-</option>
                                        <option v-for="option in options.recent_weathers" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col sm="4">
                                    <div>
                                        <label for="recent-w-3" class="label-entry">Group 3</label>
                                    </div>
                                    <b-form-select id="recent-w-3" v-model="general_data.recent_weathers[2]" size="sm" :disabled="disabled.WEATHER">
                                        <option value="">-</option>
                                        <option v-for="option in options.recent_weathers" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <b-card no-body class="bodered">
                            <b-row class="mb-2 pl-1 pt-1">
                                <b-col sm="12" class="font-weight-bolder text-primary"> AWAN </b-col>
                            </b-row>
                            <div>
                                <b-row class="pl-1">
                                    <div class="col-xl-4">
                                        <div>
                                            <label for="checkbox-clear">
                                                CLEAR
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="NSC : Tidak ada awan dengan ketinggian 1500 meter tidak ada awan CB tidak memenuhi syarat CAVOK. 
NCD : Tidak ada awan terdeteksi dengan alat"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <b-form-select id="clouds-CLEAR" v-model="general_data.clouds.CLEAR" class="mb-0" size="sm" :disabled="disabled.clouds.CLEAR">
                                            <option value="">-</option>
                                            <option v-for="(option, i) in options.clouds.CLEAR" :key="i" :value="option">
                                                {{ option }}
                                            </option>
                                        </b-form-select>
                                    </div>
                                </b-row>

                                <div class="mt-2">
                                    <div class="table-responsive text-nowrap text-center">
                                        <b-table striped hover small fixed :items="[{}, ...general_data.clouds.items]" :fields="tables.clouds.fields" class="my-table tbmetar">
                                            <!-- row pertama buat input, jadi items dikasih object kosong di row pertama -->
                                            <template v-slot:head(jumlah)="data">
                                                <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Jumlah perawanan (oktas)" /> </span>
                                            </template>
                                            <template v-slot:head(tinggi)="data">
                                                <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Tinggi dasar awan (feet)" /> </span>
                                            </template>
                                            <template v-slot:head(type)="data">
                                                <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Jenis Awan" /> </span>
                                            </template>

                                            <template #cell(jumlah)="data">
                                                <b-form-select v-if="data.index == 0" id="clouds-jumlah" v-model="general_data.clouds.jumlah" class="mb-0" size="sm" :disabled="disabled.clouds.jumlah">
                                                    <option value="">-</option>
                                                    <option v-for="option in options.clouds.jumlah_input" :key="option.value" :value="option.value">
                                                        {{ option.text }}
                                                    </option>
                                                </b-form-select>
                                                <div v-else-if="data.index - 1 === tables.clouds.edit_index">
                                                    <b-form-select v-model="general_data.clouds.items[tables.clouds.edit_index].jumlah" class="mb-0" size="sm">
                                                        <option value="">-</option>
                                                        <option v-for="option in options.clouds.jumlah" :key="option.value" :value="option.value">
                                                            {{ option.text }}
                                                        </option>
                                                    </b-form-select>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(tinggi)="data">
                                                <b-tooltip v-if="RC.m_cloud_height.status" target="cloud_height" triggers="hover">
                                                    {{ RC.m_cloud_height.message }}
                                                </b-tooltip>
                                                <b-form-input
                                                    v-if="data.index == 0"
                                                    :state="validateState($v.general_data.clouds.tinggi)"
                                                    v-model.number="$v.general_data.clouds.tinggi.$model"
                                                    type="number"
                                                    v-on:keydown="onKeyCheck($event)"
                                                    size="sm"
                                                    :disabled="disabled.clouds.tinggi"
                                                    :class="{ 'text-center': true, rangecheck: RC.m_cloud_height.status }"
                                                    id="cloud_height"
                                                    @keyup="checkValidate($event, 'cloud_height')"
                                                    @keydown="formatInteger"
                                                ></b-form-input>
                                                <div v-else-if="data.index - 1 === tables.clouds.edit_index">
                                                    <b-form-input
                                                        :state="validateState($v.general_data.clouds.items.$each[tables.clouds.edit_index].tinggi)"
                                                        v-model.number="$v.general_data.clouds.items.$each[tables.clouds.edit_index].tinggi.$model"
                                                        type="number"
                                                        v-on:keydown="onKeyCheck($event)"
                                                        size="sm"
                                                        class="text-center"
                                                        @keydown="formatInteger"
                                                    ></b-form-input>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(type)="data">
                                                <b-form-select v-if="data.index == 0" id="select-type" v-model="general_data.clouds.type" class="mb-0" size="sm" :disabled="disabled.clouds.type">
                                                    <option value="">-</option>
                                                    <option v-for="(option, i) in options.clouds.type" :key="i" :value="option">
                                                        {{ option }}
                                                    </option>
                                                </b-form-select>
                                                <div v-else-if="data.index - 1 === tables.clouds.edit_index">
                                                    <b-form-select v-model="general_data.clouds.items[tables.clouds.edit_index].type" class="mb-0" size="sm">
                                                        <option value="">-</option>
                                                        <option v-for="(option, i) in options.clouds.type" :key="i" :value="option">
                                                            {{ option }}
                                                        </option>
                                                    </b-form-select>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(action)="data">
                                                <b-button v-if="data.index == 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon rounded-circle" size="sm" @click="clouds_add()" :disabled="disabled.clouds.button">
                                                    <feather-icon icon="PlusIcon" />
                                                </b-button>

                                                <div v-else>
                                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon rounded-circle" size="sm" @click="clouds_edit(data.index - 1)">
                                                        <feather-icon icon="SaveIcon" v-if="data.index - 1 === tables.clouds.edit_index" />
                                                        <feather-icon icon="EditIcon" v-else />
                                                    </b-button>
                                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon rounded-circle ml-1" size="sm" @click="clouds_delete(data.index - 1)">
                                                        <feather-icon icon="Trash2Icon" />
                                                    </b-button>
                                                </div>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                                <div class="row mt-2 mb-4 pl-1">
                                    <div class="col-xl-5 mb-2">
                                        <div>
                                            <label for="clouds-vertical-vis"
                                                >VERTICAL VIS<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.click.v-dark.top title="jarak pandang keatas (feet)"
                                            /></label>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 mb-4">
                                        <b-form-input
                                            id="clouds-vertical-vis"
                                            class="text-center"
                                            :state="validateState($v.general_data.clouds.vertical_vis)"
                                            v-model.number="$v.general_data.clouds.vertical_vis.$model"
                                            type="number"
                                            v-on:keydown="onKeyCheck($event)"
                                            size="sm"
                                            :disabled="disabled.clouds.vertical_vis"
                                            @keydown="formatInteger"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>

                    <div class="col-xl-4">
                        <b-card no-body class="pl-1 pr-1 pt-1 bodered bg-light-warning">
                            <div>
                                <b-row style="margin-bottom: -8px;">
                                    <b-col sm="12">
                                        <b-form-group class="small" label-for="v-air-temp">
                                            <label label-for="v-air-temp">TEMPERATUR &#8451; </label>
                                            <b-form-input
                                                id="v-air-temp"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.general_data.air.air_temp)"
                                                v-model.number="$v.general_data.air.air_temp.$model"
                                                :class="{ 'text-center': true, rangecheck: RC.m_air_temp.status }"
                                                @keyup="checkValidate($event, 'air_temp')"
                                                :disabled="disabled.air.air_temp"
                                            />
                                            <b-tooltip v-if="RC.m_air_temp.status" target="v-air-temp" triggers="hover">
                                                {{ RC.m_air_temp.message }}
                                            </b-tooltip>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row style="margin-bottom: -8px;">
                                    <b-col sm="12">
                                        <b-form-group class="small" label-for="v-dew-point">
                                            <label label-for="v-dew-point">TITIK EMBUN &#8451; </label>
                                            <b-form-input id="v-dew-point" size="sm" class="text-center" :state="validateState($v.general_data.air.dew_point)" v-model.number="$v.general_data.air.dew_point.$model" :disabled="disabled.air.dew_point" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row style="margin-bottom: -8px;">
                                    <b-col sm="12">
                                        <b-form-group class="small" label="TEKANAN UDARA (QNH)" label-for="v-presure">
                                            <b-form-input
                                                id="v-presure"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.general_data.air.pressure)"
                                                v-model.number="$v.general_data.air.pressure.$model"
                                                :class="{ 'text-center': true, rangecheck: RC.m_air_pressure.status }"
                                                @keyup="checkValidate($event, 'air_pressure')"
                                                :disabled="disabled.air.pressure"
                                            />
                                            <b-tooltip v-if="RC.m_air_pressure.status" target="v-presure" triggers="hover">
                                                {{ RC.m_air_pressure.message }}
                                            </b-tooltip>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row style="margin-bottom: -8px;">
                                    <b-col sm="12">
                                        <b-form-group class="small" label-for="v-sea-temp">
                                            <label label-for="v-sea-temp">TEMP PERMUKAAN LAUT &#8451; </label>
                                            <b-form-input
                                                id="v-sea-temp"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.general_data.air.sea_surface_temp)"
                                                v-model.number="$v.general_data.air.sea_surface_temp.$model"
                                                :disabled="disabled.air.sea_surface_temp"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row style="margin-bottom: -8px;">
                                    <b-col sm="12">
                                        <b-form-group class="small" label="KEADAAN LAUT" label-for="v-sea-state">
                                            <b-form-select id="v-sea-state" v-model="general_data.air.sea_state" class="mb-0" size="sm" :disabled="disabled.air.sea_state">
                                                <option value="">-</option>
                                                <option v-for="option in options.air.sea_state" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12">
                        <b-card class="bodered">
                            <b-row class="mb-2">
                                <b-col sm="12" class="font-weight-bolder text-primary">
                                    WIND SHEAR
                                </b-col>
                            </b-row>
                            <div class="mb-1">
                                <div class="row mb-1">
                                    <div class="col-xl-4">
                                        <div>
                                            <label> WS ALL RUNWAY </label
                                            ><feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.click.v-dark.top title="Wind shear berpengaruh pada semua runway pada bandara" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <b-form-checkbox id="checkbox-ws" v-model="general_data.wind_shear.ws_all_runaway" name="checkbox-ws" size="md" :disabled="disabled.wind_shear.ws_all_runaway"> </b-form-checkbox>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="table-responsive text-nowrap">
                                        <b-table striped hover responsive small thead-class="hidden_header" :items="[{}, ...general_data.wind_shear.items]" :fields="tables.wind_shear.fields" class="tbmetar">
                                            <!-- row pertama buat input, jadi items dikasih object kosong di row pertama -->

                                            <template #cell(title)="data">
                                                <label v-if="data.index == 0">
                                                    WIND SHEAR RUNWAY
                                                </label>
                                                <label v-else></label>
                                            </template>

                                            <template #cell(runway)="data">
                                                <b-form-input
                                                    v-if="data.index == 0"
                                                    id="runway"
                                                    :state="validateState($v.general_data.wind_shear.runway)"
                                                    v-model="$v.general_data.wind_shear.runway.$model"
                                                    type="number"
                                                    v-on:keydown="onKeyCheck($event)"
                                                    size="sm"
                                                    :disabled="disabled.wind_shear.runway"
                                                    @keyup="formatLimitChar($event, 2)"
                                                    @keydown="formatInteger"
                                                    class="text-center"
                                                ></b-form-input>
                                                <div v-else-if="data.index - 1 === tables.wind_shear.edit_index">
                                                    <b-form-input v-model="general_data.wind_shear.items[tables.wind_shear.edit_index].runway" type="text" size="sm" @keyup="formatLimitChar($event, 4)" @keydown="formatInteger"></b-form-input>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(action)="data">
                                                <b-button v-if="data.index == 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon rounded-circle" size="sm" @click="wind_shear_add()" :disabled="disabled.wind_shear.button">
                                                    <feather-icon icon="PlusIcon" />
                                                </b-button>

                                                <div v-else>
                                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon rounded-circle" size="sm" @click="wind_shear_edit(data.index - 1)">
                                                        <feather-icon icon="SaveIcon" v-if="data.index - 1 === tables.wind_shear.edit_index" />
                                                        <feather-icon icon="EditIcon" v-else />
                                                    </b-button>
                                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon rounded-circle ml-1" size="sm" @click="wind_shear_delete(data.index - 1)">
                                                        <feather-icon icon="Trash2Icon" />
                                                    </b-button>
                                                </div>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12">
                        <b-card no-body class="bodered">
                            <b-row class="mb-2 pl-1 pt-1">
                                <b-col sm="12" class="font-weight-bolder mb-1 text-success">
                                    KEADAAN RUNWAY
                                </b-col>
                            </b-row>
                            <div>
                                <div class="mt-1">
                                    <div class="table-responsive text-nowrap text-center mb-4">
                                        <b-table striped hover fixed :items="[{}, ...general_data.state.items]" :fields="tables.state.fields" class="my-table tbmetar" small>
                                            <!-- row pertama buat input, jadi items dikasih object kosong di row pertama -->
                                            <template v-slot:head(state)="data">
                                                <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Jenis endapan pada runway" /> </span>
                                            </template>
                                            <template v-slot:head(contamination)="data">
                                                <span
                                                    >{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Luas runway yang tertutup endapan" />
                                                </span>
                                            </template>
                                            <template v-slot:head(depth)="data">
                                                <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Ketebalan Endapan" /> </span>
                                            </template>
                                            <template v-slot:head(braking_coefficient)="data">
                                                <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Koefesien gesekan" /> </span>
                                            </template>
                                            <template #cell(runway)="data">
                                                <b-form-input
                                                    v-if="data.index == 0"
                                                    id="state-runway"
                                                    type="number"
                                                    v-on:keydown="onKeyCheck($event)"
                                                    size="sm"
                                                    :state="validateState($v.general_data.state.runway)"
                                                    v-model="$v.general_data.state.runway.$model"
                                                    :disabled="disabled.state.runway"
                                                    @keyup="formatLimitChar($event, 2)"
                                                    @keydown="formatInteger"
                                                    class="text-center"
                                                ></b-form-input>
                                                <div v-else-if="data.index - 1 === tables.state.edit_index">
                                                    <b-form-input type="text" size="sm" v-model="general_data.state.items[tables.state.edit_index].runway" @keyup="formatLimitChar($event, 4)" @keydown="formatInteger"></b-form-input>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(state)="data">
                                                <b-form-select v-if="data.index == 0" id="state-state" class="mb-0" size="sm" v-model="general_data.state.state" :disabled="disabled.state.state">
                                                    <option value="">-</option>
                                                    <option v-for="option in options.state.state" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                </b-form-select>
                                                <div v-else-if="data.index - 1 === tables.state.edit_index">
                                                    <b-form-select class="mb-0" size="sm" v-model="general_data.state.items[tables.state.edit_index].state">
                                                        <option value="">-</option>
                                                        <option v-for="option in options.state.state" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                    </b-form-select>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(contamination)="data">
                                                <b-form-select v-if="data.index == 0" id="state-contamination" class="mb-0" size="sm" v-model="general_data.state.contamination" :disabled="disabled.state.contamination">
                                                    <option value="">-</option>
                                                    <option v-for="option in options.state.contamination" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                </b-form-select>
                                                <div v-else-if="data.index - 1 === tables.state.edit_index">
                                                    <b-form-select class="mb-0" size="sm" v-model="general_data.state.items[tables.state.edit_index].contamination">
                                                        <option value="">-</option>
                                                        <option v-for="option in options.state.contamination" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                    </b-form-select>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(depth)="data">
                                                <b-form-select v-if="data.index == 0" id="state-depth" class="mb-0" size="sm" v-model="general_data.state.depth" :disabled="disabled.state.depth">
                                                    <option value="">-</option>
                                                    <option v-for="option in options.state.depth" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                </b-form-select>
                                                <div v-else-if="data.index - 1 === tables.state.edit_index">
                                                    <b-form-select class="mb-0" size="sm" v-model="general_data.state.items[tables.state.edit_index].depth">
                                                        <option value="">-</option>
                                                        <option v-for="option in options.state.depth" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                    </b-form-select>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(braking_coefficient)="data">
                                                <b-form-select v-if="data.index == 0" id="state-braking-coefficient" class="mb-0" size="sm" v-model="general_data.state.braking_coefficient" :disabled="disabled.state.braking_coefficient">
                                                    <option value="">-</option>
                                                    <option v-for="option in options.state.braking_coefficient" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                </b-form-select>
                                                <div v-else-if="data.index - 1 === tables.state.edit_index">
                                                    <b-form-select class="mb-0" size="sm" v-model="general_data.state.items[tables.state.edit_index].braking_coefficient">
                                                        <option value="">-</option>
                                                        <option v-for="option in options.state.braking_coefficient" :key="option.value" :value="option.value"> {{ option.value }} - {{ option.text }} </option>
                                                    </b-form-select>
                                                </div>
                                                <label v-else>{{ data.value }}</label>
                                            </template>

                                            <template #cell(action)="data">
                                                <b-button v-if="data.index == 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon rounded-circle" size="sm" @click="state_add()" :disabled="disabled.state.button">
                                                    <feather-icon icon="PlusIcon" />
                                                </b-button>

                                                <div v-else>
                                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon rounded-circle" size="sm" @click="state_edit(data.index - 1)">
                                                        <feather-icon icon="SaveIcon" v-if="data.index - 1 === tables.state.edit_index" />
                                                        <feather-icon icon="EditIcon" v-else />
                                                    </b-button>
                                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon rounded-circle ml-1" size="sm" @click="state_delete(data.index - 1)">
                                                        <feather-icon icon="Trash2Icon" />
                                                    </b-button>
                                                </div>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL FOR PRESENT WEATHER -->
        <weather-modal
            ref="weather-modal"
            :weathers_modal.sync="general_data.weathers_modal[general_data.weatherGroup]"
            :code.sync="$v.general_data.weathers[general_data.weatherGroup].$model"
            :prevailing="general_data.visibility.prevailing"
        ></weather-modal>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BFormGroup,
    BCard,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    BIcon,
    BFormSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormRadioGroup,
    BCollapse,
    VBToggle,
    VBTooltip,
    BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import WeatherModal from "./WeatherModal";
import { validationMixin } from "vuelidate";
import { required, minValue, maxValue, between } from "vuelidate/lib/validators";
import {
    GC_WIND_DIRECTION,
    GC_WIND_SPEED,
    GC_WIND_GUST,
    GC_VISIBILITY_PREVAILING,
    GC_VISIBILITY_MINIMUM,
    GC_VERTICAL_VIS,
    GC_AIR_TEMP,
    GC_DEW_POINT,
    GC_PREASSURE,
    GC_SEA_SURFACE_TEMP,
    GC_CLOUD_HEIGHT,
    RC_METAR,
    validationPerN,
    VARIASI_ANGIN,
} from "@/validators/metarvalidator";

export default {
    components: {
        BRow,
        BCol,
        BTable,
        BCardText,
        BFormInput,
        BContainer,
        BButton,
        BFormGroup,
        BCard,
        BFormTimepicker,
        BFormDatepicker,
        BFormTextarea,
        BIcon,
        BFormSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormRadio,
        BFormRadioGroup,
        BCollapse,
        BTooltip,
        WeatherModal,
    },
    directives: {
        "b-toggle": VBToggle,
        "b-tooltip": VBTooltip,
        ripple: Ripple,
    },
    props: {
        isEdit: Boolean,
        isFilterComplete: Boolean,
        RC_helper: Object,
    },
    data() {
        return {
            general_data: {
                status: {
                    COR: false,
                    NIL: false,
                    AUTO: false,
                    CAVOK: false,
                },
                visibility: {
                    prevailing: "",
                    NDV: false,
                    minimum: "",
                    min_vis: "",
                },
                weatherGroup: 0,
                weathers: {
                    // 0-2 present weather
                    [0]: "",
                    [1]: "",
                    [2]: "",
                },
                weathers_modal: [
                    // 0-2 present weather
                    {
                        group_1: "",
                        group_2: "",
                        group_3: "",
                        group_4: "",
                        group_5: "",
                        none: "",
                    },
                    {
                        group_1: "",
                        group_2: "",
                        group_3: "",
                        group_4: "",
                        group_5: "",
                        none: "",
                    },
                    {
                        group_1: "",
                        group_2: "",
                        group_3: "",
                        group_4: "",
                        group_5: "",
                        none: "",
                    },
                ],
                recent_weathers: ["", "", ""],
                wind_shear: {
                    ws_all_runaway: false,
                    runway: "",
                    items: [
                        // { runway: "R24" },
                    ],
                },
                winds: {
                    VRB: false,
                    direction: "",
                    speed: "",
                    gust: "",
                    wd_dn: "",
                    wd_dx: "",
                },
                clouds: {
                    CLEAR: "",
                    jumlah: "",
                    tinggi: "",
                    type: "",
                    vertical_vis: "",
                    items: [
                        // { jumlah: "FEW", tinggi: 213213, type: "CB" },
                    ],
                },
                air: {
                    air_temp: "",
                    dew_point: "",
                    pressure: "",
                    sea_surface_temp: "",
                    sea_state: "",
                },
                rvr: {
                    runway: "",
                    designator: "",
                    vrvrvrvr: "",
                    vr_vis_min: "",
                    vr_vis_max: "",
                    ins_limit: "",
                    tendency: "",
                    items: [
                        // {
                        // 	runway: "2",
                        // 	vrvrvrvr: "2",
                        // 	vr_vis_min: "2",
                        // 	vr_vis_max: "2",
                        // 	ins_limit:"2",
                        // 	tendency: "2",
                        // },
                    ],
                },
                state: {
                    runway: "",
                    state: "",
                    contamination: "",
                    depth: "",
                    braking_coefficient: "",
                    items: [
                        // {
                        // 	runway: "2",
                        // 	state: "2",
                        // 	contamination: "2",
                        // 	depth: "2",
                        // 	braking_coefficient: "2",
                        // },
                    ],
                },
            },
            RC: {
                m_wind_speed: { status: false, message: "" },
                m_wind_gust: { status: false, message: "" },
                m_cloud_height: { status: false, message: "" },
                m_air_temp: { status: false, message: "" },
                m_air_pressure: { status: false, message: "" },
            },
            tables: {
                wind_shear: {
                    fields: ["title", "runway", "action"],
                    max_items: 8,
                    edit_index: -1,
                },
                clouds: {
                    fields: ["jumlah", { key: "tinggi", label: "tinggi (feet)" }, "type", "action"],
                    max_items: 4,
                    edit_index: -1,
                },
                rvr: {
                    fields: [
                        "runway",
                        { key: "vrvrvrvr", label: "Vr Vr Vr Vr" },
                        // "vr_vis_min",
                        // "vr_vis_max",
                        "ins_limit",
                        "tendency",
                        "action",
                    ],
                    max_items: 8,
                    edit_index: -1,
                },
                state: {
                    fields: ["runway", "state", "contamination", "depth", "braking_coefficient", "action"],
                    max_items: 8,
                    edit_index: -1,
                },
            },
            options: {
                designator: ["L", "C", "R"],
                recent_weathers: [
                    { value: "BLSN", text: "BLSN Blowing Snow" },
                    { value: "DZ", text: "DZ Drizzle" },
                    { value: "DS", text: "DS Duststorm" },
                    { value: "FC", text: "FC Funel Cloud (tornado/waterspout)" },
                    { value: "GR", text: "GR Hail" },
                    { value: "PL", text: "PL Ice pellets" },
                    { value: "RA", text: "RA Rain" },
                    { value: "GS", text: "GS Small hail/snow pellets" },
                    { value: "SN", text: "SN Snow" },
                    { value: "SS", text: "SS Sandstorm" },
                    { value: "TS", text: "TS Thunderstorm " },
                    { value: "VA", text: "VA Volcanic ash" },
                    // { value: "+DZ", text: "+DZ Heavy drizzle" },
                    // { value: "+PL", text: "+PL Heavy ice pellets" },
                    // { value: "+GR", text: "+GR Heavy hail" },
                    // { value: "+RA", text: "+RA Heavy rain" },
                    // { value: "+GS", text: "+GS Heavy small hail/snow pellets" },
                    // { value: "+SN", text: "+SN Heavy snow" },
                    { value: "FZDZ", text: "FZDZ Freezing drizzle" },
                    { value: "FZFG", text: "FZFG Freezing fog" },
                    { value: "FZRA", text: "FZRA Freezing rain" },
                    { value: "RASN", text: "RASN" },
                    { value: "SG", text: "SG" },
                    { value: "SHRA", text: "SHRA" },
                    { value: "SHSN", text: "SHSN" },
                    { value: "SHGR", text: "SHGR" },
                    { value: "SHGS", text: "SHGS" },
                    { value: "TSRA", text: "TSRA" },
                    { value: "TSSN", text: "TSSN" },
                    { value: "TSGR", text: "TSGR" },
                    { value: "UP", text: "UP" },
                    { value: "FZUP", text: "FZUP" },
                    { value: "TSUP", text: "TSUP" },
                    { value: "SHUP", text: "SHUP" },
                ],
                visibility: {
                    min_vis: ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
                },
                clouds: {
                    CLEAR: ["NSC", "NCD"],
                    jumlah_input: [
                        { value: "FEW", text: "FEW (1-2 oktas)" },
                        { value: "SCT", text: "SCT (3-4 oktas)" },
                        { value: "BKN", text: "BKN (5-7 oktas)" },
                        { value: "OVC", text: "OVC (8 oktas)" },
                    ],
                    jumlah: [
                        { value: "FEW", text: "FEW (1-2 oktas)" },
                        { value: "SCT", text: "SCT (3-4 oktas)" },
                        { value: "BKN", text: "BKN (5-7 oktas)" },
                        { value: "OVC", text: "OVC (8 oktas)" },
                    ],
                    type: ["CB", "TCU"],
                },
                air: {
                    sea_state: [
                        { value: 0, text: "Calm (glassy)" },
                        { value: 1, text: "Calm (rippled)" },
                        { value: 2, text: "Smooth (wavelets)" },
                        { value: 3, text: "Slight" },
                        { value: 4, text: "Moderate" },
                        { value: 5, text: "Rough" },
                        { value: 6, text: "Very rough" },
                        { value: 7, text: "High" },
                        { value: 8, text: "Very high" },
                        { value: 9, text: "Phenomenal" },
                    ],
                },
                rvr: {
                    vr_vis_min: [0, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 450, 500, 550, 600, 650, 700, 750, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000],
                    vr_vis_max: [0, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 450, 500, 550, 600, 650, 700, 750, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000],
                    ins_limit: [
                        { value: "M", text: "More" },
                        { value: "L", text: "Less" },
                    ],
                    tendency: [
                        { value: "N", text: "None" },
                        { value: "U", text: "Upward" },
                        { value: "D", text: "Downward" },
                    ],
                },
                state: {
                    state: [
                        { value: 0, text: "Clear and dry" },
                        { value: 1, text: "Damp" },
                        { value: 2, text: "Wet and water patches" },
                        { value: 3, text: "Rime and frost covered" },
                        { value: 4, text: "Dry snow" },
                        { value: 5, text: "Wet snow" },
                        { value: 6, text: "Slush" },
                        { value: 7, text: "Ice" },
                        { value: 8, text: "Compacted or rolled snow" },
                        { value: 9, text: "Frozen ruts or ridges" },
                    ],
                    contamination: [
                        { value: "0", text: "0% to 10% of runway contaminated" },
                        { value: "1", text: "11% to 25% of runway contaminated" },
                        { value: "5", text: "26% to 50% of runway contaminated" },
                        { value: "9", text: "51% to 100% of runway contaminated" },
                        { value: "/", text: "Not reported" },
                    ],
                    depth: [
                        { value: "00", text: "Less than 1 mm" },
                        { value: "01", text: "1 mm" },
                        { value: "02", text: "2 mm" },
                        { value: "03", text: "3 mm" },
                        { value: "04", text: "4 mm" },
                        { value: "05", text: "5 mm" },
                        { value: "06", text: "6 mm" },
                        { value: "07", text: "7 mm" },
                        { value: "08", text: "8 mm" },
                        { value: "09", text: "9 mm" },
                        { value: "10", text: "10 mm" },
                        { value: "11", text: "11 mm" },
                        { value: "12", text: "12 mm" },
                        { value: "13", text: "13 mm" },
                        { value: "14", text: "14 mm" },
                        { value: "15", text: "15 mm" },
                        { value: "16", text: "16 mm" },
                        { value: "17", text: "17 mm" },
                        { value: "18", text: "18 mm" },
                        { value: "19", text: "19 mm" },
                        { value: "20", text: "20 mm" },
                        { value: "21", text: "21 mm" },
                        { value: "22", text: "22 mm" },
                        { value: "23", text: "23 mm" },
                        { value: "24", text: "24 mm" },
                        { value: "25", text: "25 mm" },
                        { value: "26", text: "26 mm" },
                        { value: "27", text: "27 mm" },
                        { value: "28", text: "28 mm" },
                        { value: "29", text: "29 mm" },
                        { value: "30", text: "30 mm" },
                        { value: "31", text: "31 mm" },
                        { value: "32", text: "32 mm" },
                        { value: "33", text: "33 mm" },
                        { value: "34", text: "34 mm" },
                        { value: "35", text: "35 mm" },
                        { value: "36", text: "36 mm" },
                        { value: "37", text: "37 mm" },
                        { value: "38", text: "38 mm" },
                        { value: "39", text: "39 mm" },
                        { value: "40", text: "40 mm" },
                        { value: "41", text: "41 mm" },
                        { value: "42", text: "42 mm" },
                        { value: "43", text: "43 mm" },
                        { value: "44", text: "44 mm" },
                        { value: "45", text: "45 mm" },
                        { value: "46", text: "46 mm" },
                        { value: "47", text: "47 mm" },
                        { value: "48", text: "48 mm" },
                        { value: "49", text: "49 mm" },
                        { value: "50", text: "50 mm" },
                        { value: "51", text: "51 mm" },
                        { value: "52", text: "52 mm" },
                        { value: "53", text: "53 mm" },
                        { value: "54", text: "54 mm" },
                        { value: "55", text: "55 mm" },
                        { value: "56", text: "56 mm" },
                        { value: "57", text: "57 mm" },
                        { value: "58", text: "58 mm" },
                        { value: "59", text: "59 mm" },
                        { value: "60", text: "60 mm" },
                        { value: "61", text: "61 mm" },
                        { value: "62", text: "62 mm" },
                        { value: "63", text: "63 mm" },
                        { value: "64", text: "64 mm" },
                        { value: "65", text: "65 mm" },
                        { value: "66", text: "66 mm" },
                        { value: "67", text: "67 mm" },
                        { value: "68", text: "68 mm" },
                        { value: "69", text: "69 mm" },
                        { value: "70", text: "70 mm" },
                        { value: "71", text: "71 mm" },
                        { value: "72", text: "72 mm" },
                        { value: "73", text: "73 mm" },
                        { value: "74", text: "74 mm" },
                        { value: "75", text: "75 mm" },
                        { value: "76", text: "76 mm" },
                        { value: "77", text: "77 mm" },
                        { value: "78", text: "78 mm" },
                        { value: "79", text: "79 mm" },
                        { value: "80", text: "80 mm" },
                        { value: "81", text: "81 mm" },
                        { value: "82", text: "82 mm" },
                        { value: "83", text: "83 mm" },
                        { value: "84", text: "84 mm" },
                        { value: "85", text: "85 mm" },
                        { value: "86", text: "86 mm" },
                        { value: "87", text: "87 mm" },
                        { value: "88", text: "88 mm" },
                        { value: "89", text: "89 mm" },
                        { value: "90", text: "90 mm" },
                        { value: "92", text: "10 cm" },
                        { value: "93", text: "15 cm" },
                        { value: "94", text: "20 cm" },
                        { value: "95", text: "25 cm" },
                        { value: "96", text: "30 cm" },
                        { value: "97", text: "35 cm" },
                        { value: "98", text: "40 cm or more" },
                        { value: "99", text: "Not reported/runway not operational" },
                        { value: "//", text: "Not significant/not measurable" },
                    ],
                    braking_coefficient: [
                        { value: "00", text: "Friction coefficient 0.0" },
                        { value: "01", text: "Friction coefficient 0.1" },
                        { value: "02", text: "Friction coefficient 0.2" },
                        { value: "03", text: "Friction coefficient 0.3" },
                        { value: "04", text: "Friction coefficient 0.4" },
                        { value: "05", text: "Friction coefficient 0.5" },
                        { value: "06", text: "Friction coefficient 0.6" },
                        { value: "07", text: "Friction coefficient 0.7" },
                        { value: "08", text: "Friction coefficient 0.8" },
                        { value: "09", text: "Friction coefficient 0.9" },
                        { value: "10", text: "Friction coefficient 0.10" },
                        { value: "11", text: "Friction coefficient 0.11" },
                        { value: "12", text: "Friction coefficient 0.12" },
                        { value: "13", text: "Friction coefficient 0.13" },
                        { value: "14", text: "Friction coefficient 0.14" },
                        { value: "15", text: "Friction coefficient 0.15" },
                        { value: "16", text: "Friction coefficient 0.16" },
                        { value: "17", text: "Friction coefficient 0.17" },
                        { value: "18", text: "Friction coefficient 0.18" },
                        { value: "19", text: "Friction coefficient 0.19" },
                        { value: "20", text: "Friction coefficient 0.20" },
                        { value: "21", text: "Friction coefficient 0.21" },
                        { value: "22", text: "Friction coefficient 0.22" },
                        { value: "23", text: "Friction coefficient 0.23" },
                        { value: "24", text: "Friction coefficient 0.24" },
                        { value: "25", text: "Friction coefficient 0.25" },
                        { value: "26", text: "Friction coefficient 0.26" },
                        { value: "27", text: "Friction coefficient 0.27" },
                        { value: "28", text: "Friction coefficient 0.28" },
                        { value: "29", text: "Friction coefficient 0.29" },
                        { value: "30", text: "Friction coefficient 0.30" },
                        { value: "31", text: "Friction coefficient 0.31" },
                        { value: "32", text: "Friction coefficient 0.32" },
                        { value: "33", text: "Friction coefficient 0.33" },
                        { value: "34", text: "Friction coefficient 0.34" },
                        { value: "35", text: "Friction coefficient 0.35" },
                        { value: "36", text: "Friction coefficient 0.36" },
                        { value: "37", text: "Friction coefficient 0.37" },
                        { value: "38", text: "Friction coefficient 0.38" },
                        { value: "39", text: "Friction coefficient 0.39" },
                        { value: "40", text: "Friction coefficient 0.40" },
                        { value: "41", text: "Friction coefficient 0.41" },
                        { value: "42", text: "Friction coefficient 0.42" },
                        { value: "43", text: "Friction coefficient 0.43" },
                        { value: "44", text: "Friction coefficient 0.44" },
                        { value: "45", text: "Friction coefficient 0.45" },
                        { value: "46", text: "Friction coefficient 0.46" },
                        { value: "47", text: "Friction coefficient 0.47" },
                        { value: "48", text: "Friction coefficient 0.48" },
                        { value: "49", text: "Friction coefficient 0.49" },
                        { value: "50", text: "Friction coefficient 0.50" },
                        { value: "51", text: "Friction coefficient 0.51" },
                        { value: "52", text: "Friction coefficient 0.52" },
                        { value: "53", text: "Friction coefficient 0.53" },
                        { value: "54", text: "Friction coefficient 0.54" },
                        { value: "55", text: "Friction coefficient 0.55" },
                        { value: "56", text: "Friction coefficient 0.56" },
                        { value: "57", text: "Friction coefficient 0.57" },
                        { value: "58", text: "Friction coefficient 0.58" },
                        { value: "59", text: "Friction coefficient 0.59" },
                        { value: "60", text: "Friction coefficient 0.60" },
                        { value: "61", text: "Friction coefficient 0.61" },
                        { value: "62", text: "Friction coefficient 0.62" },
                        { value: "63", text: "Friction coefficient 0.63" },
                        { value: "64", text: "Friction coefficient 0.64" },
                        { value: "65", text: "Friction coefficient 0.65" },
                        { value: "66", text: "Friction coefficient 0.66" },
                        { value: "67", text: "Friction coefficient 0.67" },
                        { value: "68", text: "Friction coefficient 0.68" },
                        { value: "69", text: "Friction coefficient 0.69" },
                        { value: "70", text: "Friction coefficient 0.70" },
                        { value: "71", text: "Friction coefficient 0.71" },
                        { value: "72", text: "Friction coefficient 0.72" },
                        { value: "73", text: "Friction coefficient 0.73" },
                        { value: "74", text: "Friction coefficient 0.74" },
                        { value: "75", text: "Friction coefficient 0.75" },
                        { value: "76", text: "Friction coefficient 0.76" },
                        { value: "77", text: "Friction coefficient 0.77" },
                        { value: "78", text: "Friction coefficient 0.78" },
                        { value: "79", text: "Friction coefficient 0.79" },
                        { value: "80", text: "Friction coefficient 0.80" },
                        { value: "81", text: "Friction coefficient 0.81" },
                        { value: "82", text: "Friction coefficient 0.82" },
                        { value: "83", text: "Friction coefficient 0.83" },
                        { value: "84", text: "Friction coefficient 0.84" },
                        { value: "85", text: "Friction coefficient 0.85" },
                        { value: "86", text: "Friction coefficient 0.86" },
                        { value: "87", text: "Friction coefficient 0.87" },
                        { value: "88", text: "Friction coefficient 0.88" },
                        { value: "89", text: "Friction coefficient 0.89" },
                        { value: "90", text: "Friction coefficient 0.90" },
                        { value: "91", text: "Braking action poor" },
                        { value: "92", text: "Braking action medium/poor" },
                        { value: "93", text: "Braking action medium" },
                        { value: "94", text: "Braking action medium/good" },
                        { value: "95", text: "Braking action good" },
                        { value: "99", text: "Unreliable" },
                        { value: "//", text: "Not reported/runway not operational" },
                    ],
                },
            },
        };
    },
    mixins: [validationMixin],
    validations() {
        return {
            general_data: {
                winds: {
                    direction: {
                        ...(!(this.general_data.status.NIL || this.general_data.status.AUTO) && !this.general_data.winds.VRB ? { required } : {}),
                        GC_WIND_DIRECTION,
                        validationPerN: validationPerN(10),
                    },
                    speed: {
                        ...(!(this.general_data.status.NIL || this.general_data.status.AUTO) ? { required } : {}),
                        GC_WIND_SPEED,
                    },
                    gust: {
                        GC_WIND_GUST,
                        minValue: minValue(this.general_data.winds.speed + 10),
                    },
                    wd_dn: {
                        // between: between(this.general_data.winds.wd_dx - 180, this.general_data.winds.wd_dx - 60),
                        variasi_angin() {
                            // console.log("disabled.winds.wd_dn", this.disabled.winds.wd_dn, this.disabled.winds.wd_dx);
                            if (!this.disabled.winds.wd_dn && !this.disabled.winds.wd_dx) {
                                if (this.general_data.winds.wd_dn !== "" && this.general_data.winds.wd_dx !== "" && this.general_data.winds.wd_dn >= 0 && this.general_data.winds.wd_dx >= 0) {
                                    // let from = this.general_data.winds.wd_dn;
                                    // let to = this.general_data.winds.wd_dx;
                                    // let dif = to < from ? 360 - from + to : to - from;
                                    // // console.log("wd_dn", dif >= 60 && dif <= 180, "dn=", this.general_data.winds.wd_dn, "dx=", this.general_data.winds.wd_dx, this.disabled.winds.wd_dn, this.disabled.winds.wd_dx);
                                    // return dif >= 60 && dif <= 180;
                                } else {
                                    // console.log("variasi_angin", "-" + this.general_data.winds.wd_dn, "-" + this.general_data.winds.wd_dx);
                                    return false;
                                }
                            }
                            return true;
                        },
                        GC_WIND_DIRECTION,
                    },
                    wd_dx: {
                        // between: between(this.general_data.winds.wd_dn + 60, this.general_data.winds.wd_dn + 180),
                        variasi_angin() {
                            if (!this.disabled.winds.wd_dn && !this.disabled.winds.wd_dx) {
                                if (this.general_data.winds.wd_dn !== "" && this.general_data.winds.wd_dx !== "" && this.general_data.winds.wd_dn >= 0 && this.general_data.winds.wd_dx >= 0) {
                                    // let from = this.general_data.winds.wd_dn;
                                    // let to = this.general_data.winds.wd_dx;
                                    // let dif = to < from ? 360 - from + to : to - from;
                                    // // console.log("wd_dn", dif >= 60 && dif <= 180, "dn=", this.general_data.winds.wd_dn, "dx=", this.general_data.winds.wd_dx, this.disabled.winds.wd_dn, this.disabled.winds.wd_dx);
                                    // return dif >= 60 && dif <= 180;
                                } else {
                                    // console.log("variasi_angin", "-" + this.general_data.winds.wd_dn, "-" + this.general_data.winds.wd_dx);
                                    return false;
                                }
                            }
                            return true;
                        },
                        GC_WIND_DIRECTION,
                    },
                },
                visibility: {
                    prevailing: {
                        ...(!(this.general_data.status.NIL || this.general_data.status.AUTO) ? { required } : {}),
                        GC_VISIBILITY_PREVAILING,
                    },
                    minimum: {
                        persen50: maxValue(this.general_data.visibility.prevailing >= 3000 ? this.general_data.visibility.prevailing / 2 : 1500),
                        prevailing: maxValue(this.general_data.visibility.prevailing),
                        GC_VISIBILITY_MINIMUM,
                    },
                },
                weathers: {
                    [0]: { unik: (val) => val === "" || Object.values(this.general_data.weathers).every((item, i) => i == 0 || val !== item) },
                    [1]: { unik: (val) => val === "" || Object.values(this.general_data.weathers).every((item, i) => i == 1 || val !== item) },
                    [2]: { unik: (val) => val === "" || Object.values(this.general_data.weathers).every((item, i) => i == 2 || val !== item) },
                },
                rvr: {
                    runway: {
                        between: between(1, 36),
                    },
                },
                wind_shear: {
                    runway: {
                        between: between(1, 36),
                    },
                },
                state: {
                    runway: {
                        between: between(1, 36),
                    },
                },
                clouds: {
                    tinggi: {
                        GC_CLOUD_HEIGHT,
                        unik: (val, row) => {
                            // return !this.general_data.clouds.items.some((item) => item.tinggi == val && item.type == row.type && item.jumlah == row.jumlah && count++);
                            // var count = 0;
                            // this.general_data.clouds.items.forEach((item) => item.tinggi == val && item.type == row.type && item.jumlah == row.jumlah && count++);

                            // if (count >= 2) return false;
                            // else return true;
                            let count = 0;
                            let tmpItems = [];
                            this.general_data.clouds.items.forEach((item) => {
                                if (item.tinggi == val) {
                                    tmpItems.push(item);
                                    count++;
                                }
                            });

                            if (count > 0 && count <= 1) {
                                // console.log("0-1");
                                return !this.general_data.clouds.items.some((item) => item.tinggi == val && item.type == row.type && item.jumlah == row.jumlah);
                            }
                            if (count > 1) {
                                // console.log(">1 else ");
                                return false;
                            } else {
                                // console.log("0 else ");
                                return true;
                            }
                        },
                    },
                    items: {
                        $each: {
                            tinggi: { GC_CLOUD_HEIGHT },
                        },
                    },
                    vertical_vis: { GC_VERTICAL_VIS },
                },
                air: {
                    air_temp: {
                        ...(!(this.general_data.status.NIL || this.general_data.status.AUTO) ? { required } : {}),
                        GC_AIR_TEMP,
                    },
                    dew_point: {
                        ...(!(this.general_data.status.NIL || this.general_data.status.AUTO) ? { required } : {}),
                        GC_DEW_POINT,
                        maxValue: maxValue(this.general_data.air.air_temp),
                    },
                    pressure: {
                        ...(!(this.general_data.status.NIL || this.general_data.status.AUTO) ? { required } : {}),
                        GC_PREASSURE,
                    },
                    sea_surface_temp: { GC_SEA_SURFACE_TEMP },
                },
            },
        };
    },
    computed: {
        disabled: function () {
            let all = this.general_data.status.NIL || this.general_data.status.AUTO || !this.isFilterComplete;

            return {
                status: {
                    COR: this.general_data.status.NIL || this.general_data.status.AUTO || this.isEdit || !this.isFilterComplete,
                    NIL: this.general_data.status.COR || this.general_data.status.AUTO || this.isEdit || !this.isFilterComplete,
                    AUTO: this.general_data.status.NIL || this.general_data.status.COR || this.isEdit || !this.isFilterComplete,
                    CAVOK: all,
                },
                WEATHER: all || this.general_data.status.CAVOK,
                visibility: {
                    prevailing: all || this.general_data.status.CAVOK,
                    NDV: all || this.general_data.status.CAVOK,
                    minimum: all || this.general_data.visibility.prevailing >= 5000 || this.general_data.status.CAVOK || this.general_data.visibility.NDV,
                    min_vis:
                        all ||
                        this.general_data.visibility.minimum == "" ||
                        (this.general_data.visibility.prevailing > 5000 && this.general_data.visibility.minimum > 1500) ||
                        (this.general_data.visibility.prevailing >= 3000 ? this.general_data.visibility.minimum > this.general_data.visibility.prevailing / 2 : this.general_data.visibility.minimum > 1500) ||
                        this.general_data.visibility.minimum > this.general_data.visibility.prevailing ||
                        this.general_data.status.CAVOK ||
                        this.general_data.visibility.NDV,
                },
                wind_shear: {
                    ws_all_runaway: all || false,
                    runway: all || this.general_data.wind_shear.ws_all_runaway,
                    button: all || this.general_data.wind_shear.runway === "",
                },
                winds: {
                    VRB: all || false,
                    direction: all || false,
                    speed: all || false,
                    gust: all || false,
                    wd_dn: all || !(this.general_data.winds.speed >= 3 && this.general_data.winds.VRB),
                    wd_dx: all || !(this.general_data.winds.speed >= 3 && this.general_data.winds.VRB),
                },
                clouds: {
                    CLEAR: all || false,
                    jumlah: all || this.general_data.clouds.CLEAR !== "",
                    tinggi: all || this.general_data.clouds.CLEAR !== "",
                    type: all || this.general_data.clouds.CLEAR !== "" || this.general_data.status.CAVOK,
                    vertical_vis: all || this.general_data.clouds.CLEAR !== "",
                    button: all || !((this.general_data.clouds.jumlah !== "" && this.general_data.clouds.tinggi !== "" && this.general_data.clouds.type !== "") || (this.general_data.clouds.jumlah !== "" && this.general_data.clouds.tinggi !== "")),
                },
                air: {
                    air_temp: all,
                    dew_point: all,
                    pressure: all,
                    sea_surface_temp: all,
                    sea_state: all,
                },
                rvr: {
                    runway: all || !(this.general_data.visibility.prevailing <= 1400) || false,
                    vrvrvrvr: all || !(this.general_data.visibility.prevailing <= 1400) || this.general_data.rvr.vr_vis_min !== "" || this.general_data.rvr.vr_vis_max !== "",
                    vr_vis_min: all || !(this.general_data.visibility.prevailing <= 1400) || this.general_data.rvr.vrvrvrvr !== "" || this.general_data.rvr.ins_limit !== "" || this.general_data.rvr.tendency !== "",
                    vr_vis_max: all || !(this.general_data.visibility.prevailing <= 1400) || this.general_data.rvr.vrvrvrvr !== "" || this.general_data.rvr.ins_limit !== "" || this.general_data.rvr.tendency !== "",
                    ins_limit: all || !(this.general_data.visibility.prevailing <= 1400) || this.general_data.rvr.tendency !== "" || this.general_data.rvr.vr_vis_min !== "" || this.general_data.rvr.vr_vis_max !== "",
                    tendency: all || !(this.general_data.visibility.prevailing <= 1400) || this.general_data.rvr.ins_limit !== "" || this.general_data.rvr.vr_vis_min !== "" || this.general_data.rvr.vr_vis_max !== "",
                    button:
                        all ||
                        !(
                            (this.general_data.rvr.runway !== "" && this.general_data.rvr.vrvrvrvr !== "" && (this.general_data.rvr.ins_limit !== "" || this.general_data.rvr.tendency !== "")) ||
                            (this.general_data.rvr.runway !== "" && this.general_data.rvr.vr_vis_min !== "" && this.general_data.rvr.vr_vis_max !== "")
                        ),
                },
                state: {
                    runway: all || false,
                    state: all || false,
                    contamination: all || false,
                    depth: all || false,
                    braking_coefficient: all || false,
                    button:
                        all ||
                        this.general_data.state.runway === "" ||
                        this.general_data.state.state === "" ||
                        this.general_data.state.contamination === "" ||
                        this.general_data.state.depth === "" ||
                        this.general_data.state.braking_coefficient === "",
                },
            };
        },
    },
    //validate
    methods: {
        onKeyCheck(event) {
            // console.log("onKeyCheck", event);
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
        },
        padZero(val, n) {
            return `${"0".repeat(n)}${val}`.substr(-n, n);
        },
        formatInteger(event) {
            switch (event.key) {
                case "e":
                case ".":
                case ",":
                    event.preventDefault();
                    break;
            }
        },
        formatLimitChar(event, max) {
            if (event.target.value.length >= max) {
                event.target.value = event.target.value.substr(0, max);
            }
        },
        checkValidate: function (event, type) {
            let value = event.target.value;

            // GROSS CHECK
            let gc = false;
            switch (type) {
                case "wind_speed":
                    gc = GC_WIND_SPEED(value);
                    break;
                case "wind_gust":
                    gc = GC_WIND_GUST(value);
                    break;
                case "cloud_height":
                    gc = GC_CLOUD_HEIGHT(value);
                    break;
                case "air_temp":
                    gc = GC_AIR_TEMP(value);
                    break;
                case "air_pressure":
                    gc = GC_PREASSURE(value);
                    break;
                default:
                    break;
            }

            // RANGE CHECK
            let raw = { status: true, message: "" };
            switch (type) {
                case "wind_speed":
                    raw = this.RC_helper.check(value, "m_wind_speed_ff");
                    break;
                case "wind_gust":
                    raw = this.RC_helper.check(value, "m_wind_gust_fmfm");
                    break;
                case "cloud_height":
                    raw = this.RC_helper.check(value, "m_cloud_height_hshshs");
                    break;
                case "air_temp":
                    raw = this.RC_helper.check(value, "m_air_temperature_tt");
                    break;
                case "air_pressure":
                    raw = this.RC_helper.check(value, "m_qnh_pressure_phphph");
                    break;
                default:
                    break;
            }

            // SET VALIDATION STATUS
            this.RC["m_" + type].status = gc && !raw.status;
            this.RC["m_" + type].message = raw.message;
            this.RC["m_" + type].value = value;
            // console.log("RC: ", this.RC);
        },
        checkValidateEdit(value, type) {
            // GROSS CHECK
            let gc = false;
            switch (type) {
                case "wind_speed":
                    gc = GC_WIND_SPEED(value);
                    break;
                case "wind_gust":
                    gc = GC_WIND_GUST(value);
                    break;
                case "cloud_height":
                    gc = GC_CLOUD_HEIGHT(value);
                    break;
                case "air_temp":
                    gc = GC_AIR_TEMP(value);
                    break;
                case "air_pressure":
                    gc = GC_PREASSURE(value);
                    break;
                default:
                    break;
            }

            // RANGE CHECK
            let raw = { status: true, message: "" };
            switch (type) {
                case "wind_speed":
                    raw = this.RC_helper.check(value, "m_wind_speed_ff");
                    break;
                case "wind_gust":
                    raw = this.RC_helper.check(value, "m_wind_gust_fmfm");
                    break;
                case "cloud_height":
                    raw = this.RC_helper.check(value, "m_cloud_height_hshshs");
                    break;
                case "air_temp":
                    raw = this.RC_helper.check(value, "m_air_temperature_tt");
                    break;
                case "air_pressure":
                    raw = this.RC_helper.check(value, "m_qnh_pressure_phphph");
                    break;
                default:
                    break;
            }

            // SET VALIDATION STATUS
            this.RC["m_" + type].status = gc && !raw.status;
            this.RC["m_" + type].message = raw.message;
            this.RC["m_" + type].value = value;
            // console.log("RC: ", this.RC);
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        runway_char(runway) {
            let result = `${runway}`;
            if (!result.startsWith("R")) {
                result = "R" + result;
            }
            return result;
        },
        openWeatherModal(group) {
            this.general_data.weatherGroup = group;
            this.$refs["weather-modal"].modal_data = this.general_data.weathers_modal[this.general_data.weatherGroup];
            this.$refs["weather-modal"].$refs["modal-component"].show();
        },
        wind_shear_add() {
            if (this.$v.general_data.wind_shear.runway.$invalid) return false;

            if (this.general_data.wind_shear.items.length < this.tables.wind_shear.max_items) {
                this.general_data.wind_shear.items.push({
                    runway: this.runway_char(this.padZero(this.general_data.wind_shear.runway, 2)),
                });
                this.general_data.wind_shear.runway = "";
            } else {
            }
        },
        wind_shear_edit(index) {
            if (this.tables.wind_shear.edit_index === index) {
                this.tables.wind_shear.edit_index = -1;
            } else {
                this.tables.wind_shear.edit_index = index;
            }
        },
        wind_shear_delete(index) {
            this.general_data.wind_shear.items = this.general_data.wind_shear.items.filter((item, i) => i !== index);
        },
        clouds_add() {
            if (this.$v.general_data.clouds.tinggi.$invalid) return false;

            if (this.general_data.clouds.items.length < this.tables.clouds.max_items) {
                this.general_data.clouds.items = [
                    ...this.general_data.clouds.items,
                    {
                        jumlah: this.general_data.clouds.jumlah,
                        tinggi: this.general_data.clouds.tinggi,
                        type: this.general_data.clouds.type,
                    },
                ];
                this.general_data.clouds.jumlah = "";
                this.general_data.clouds.tinggi = "";
                this.general_data.clouds.type = "";
            } else {
            }
        },
        clouds_edit(index) {
            if (this.tables.clouds.edit_index === index) {
                if (this.$v.general_data.clouds.items.$each[this.tables.clouds.edit_index].tinggi.$invalid) return false;
                this.tables.clouds.edit_index = -1;
            } else {
                this.tables.clouds.edit_index = index;
            }
        },
        clouds_delete(index) {
            this.general_data.clouds.items = this.general_data.clouds.items.filter((item, i) => i !== index);
        },
        rvr_add() {
            if (this.$v.general_data.rvr.runway.$invalid) return false;

            if (this.general_data.rvr.items.length < this.tables.rvr.max_items) {
                this.general_data.rvr.items.push({
                    runway: this.runway_char(this.padZero(this.general_data.rvr.runway, 2) + (this.general_data.rvr.designator || "")),
                    vrvrvrvr: this.general_data.rvr.vrvrvrvr,
                    vr_vis_min: this.general_data.rvr.vr_vis_min,
                    vr_vis_max: this.general_data.rvr.vr_vis_max,
                    ins_limit: this.general_data.rvr.ins_limit,
                    tendency: this.general_data.rvr.tendency,
                });
                this.general_data.rvr.runway = "";
                this.general_data.rvr.designator = "";
                this.general_data.rvr.vrvrvrvr = "";
                this.general_data.rvr.vr_vis_min = "";
                this.general_data.rvr.vr_vis_max = "";
                this.general_data.rvr.ins_limit = "";
                this.general_data.rvr.tendency = "";
            } else {
            }
        },
        rvr_edit(index) {
            if (this.tables.rvr.edit_index === index) {
                this.tables.rvr.edit_index = -1;
            } else {
                this.tables.rvr.edit_index = index;
            }
        },
        rvr_delete(index) {
            this.general_data.rvr.items = this.general_data.rvr.items.filter((item, i) => i !== index);
        },
        state_add() {
            if (this.$v.general_data.state.runway.$invalid) return false;

            if (this.general_data.state.items.length < this.tables.state.max_items) {
                this.general_data.state.items.push({
                    runway: this.runway_char(this.padZero(this.general_data.state.runway, 2)),
                    state: this.general_data.state.state,
                    contamination: this.general_data.state.contamination,
                    depth: this.general_data.state.depth,
                    braking_coefficient: this.general_data.state.braking_coefficient,
                });
                this.general_data.state.runway = "";
                this.general_data.state.state = "";
                this.general_data.state.contamination = "";
                this.general_data.state.depth = "";
                this.general_data.state.braking_coefficient = "";
            } else {
            }
        },
        state_edit(index) {
            if (this.tables.state.edit_index === index) {
                this.tables.state.edit_index = -1;
            } else {
                this.tables.state.edit_index = index;
            }
        },
        state_delete(index) {
            this.general_data.state.items = this.general_data.state.items.filter((item, i) => i !== index);
        },
    },
    watch: {
        // "general_data.clouds.items": function(after) {
        //   const layer = after.length;
        //   const opt_1 = [
        //     { value: "FEW", text: "FEW (1-2 oktas)" },
        //     { value: "SCT", text: "SCT (3-4 oktas)" },
        //     { value: "BKN", text: "BKN (5-7 oktas)" },
        //     { value: "OVC", text: "OVC (8 oktas)" },
        //   ];
        //   const opt_2 = [
        //     { value: "SCT", text: "SCT (3-4 oktas)" },
        //     { value: "BKN", text: "BKN (5-7 oktas)" },
        //     { value: "OVC", text: "OVC (8 oktas)" },
        //   ];
        //   const opt_3 = [
        //     { value: "BKN", text: "BKN (5-7 oktas)" },
        //     { value: "OVC", text: "OVC (8 oktas)" },
        //   ];

        //   if (layer == 0) {
        //     this.options.clouds.jumlah_input = opt_1;
        //   }else if(layer == 1) {
        //     this.options.clouds.jumlah_input = opt_2;
        //   }else if(layer == 2) {
        //     this.options.clouds.jumlah_input = opt_3;
        //   }else if(layer == 3) {
        //     this.options.clouds.jumlah_input = opt_3;
        //   }
        // },
        // "general_data.winds.speed":function(val){
        //   console.log("general_data.winds.speed : ",val)
        // },
        "general_data.status.CAVOK": function (after) {
            if (after) {
                this.general_data.visibility.prevailing = 10000;
            } else {
                this.general_data.visibility.prevailing = "";
            }
        },
        "general_data.winds.VRB": function (after) {
            if (after) {
            } else {
                this.general_data.winds.wd_dn = "";
                this.general_data.winds.wd_dx = "";
            }
        },
        "general_data.air.pressure": function (after) {
            this.checkValidateEdit(after, "air_pressure");
            // console.log("pressure : ", after);
        },
        "general_data.air.air_temp": function (after) {
            this.checkValidateEdit(after, "air_temp");
            // console.log("air_temp : ", after);
        },
        "general_data.clouds.tinggi": function (after) {
            this.checkValidateEdit(after, "cloud_height");
            // console.log("checkValidateEdit- cloud_height : ", after);
        },
        "general_data.winds.gust": function (after) {
            this.checkValidateEdit(after, "wind_gust");
            // console.log("wind_gustd : ", after);
        },
        "general_data.winds.speed": function (after) {
            if (after < 3) {
                this.general_data.winds.wd_dn = "";
                this.general_data.winds.wd_dx = "";
            }
            this.checkValidateEdit(after, "wind_speed");
            // console.log("wind_speed : ", after);
        },
        "disabled.visibility.minimum": function (after) {
            if (after) {
                this.general_data.visibility.minimum = "";
            }
        },
        "disabled.visibility.min_vis": function (after) {
            if (after) {
                this.general_data.visibility.min_vis = "";
            }
        },
        general_data: {
            deep: true,
            handler() {
                this.$emit("update:general_data", this.general_data);
            },
        },
    },
};
</script>

<style scoped>
.rangecheck {
    border-color: rgb(248, 228, 72) !important;
    background-color: rgb(237, 252, 109) !important;
}

.custom-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.label-entry {
    text-align: center;
    /* color: white; */
    width: 100%;
    /* font-weight: bold !important; */
    /* border: 1px solid #858585;; */
    /* border-radius: 3px; */
    /* background: #858585; */
    padding: 4px;
}

.button-weather:disabled {
    background: #efefef !important;
}

.button-weather.is-invalid {
    background: #ffab94 !important;
}

.wrap {
    overflow: hidden;
    width: 100%;
    flex-direction: row;
    white-space: nowrap;
    display: flex;
    overflow-x: auto;
}
</style>

<style>
.hidden_header {
    display: none;
}

.my-table td {
    padding: 2px;
}
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}

.table.tbmetar {
    width: auto;
}
.table.tbmetar thead th,
.table.tbmetar tfoot th {
    vertical-align: top;
    font-size: 0.757rem;
    letter-spacing: -0.5px;
}

.bodered {
    border: 1px solid #b4b7bd;
}
</style>
