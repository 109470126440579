export function GC_PIBAL_ARAH_ANGIN_PERMUKAAN(value) {
  return (value >= 0 && value <= 360) || value == 9999;
}
export function GC_PIBAL_KEC_ANGIN_PERMUKAAN(value) {
  return (value >= 0 && value <= 60) || value == 9999;
}
export function GC_ELEVASI_STATION(value) {
  return (value >= 0 && value <= 9999) || value == 9999;
}
export function GC_AZIMUT(value) {
  return (value >= 0 && value <= 360) || value == 9999;
}
export function GC_ELEVASI(value) {
  return (value >= 0 && value <= 90) || value == 9999;
}


const GC = {
  WIN_DIR_SURFACE: { min: 0, max: 360, specialValue: [9999] },
  WIN_SPEED_SURFACE: { min: 0, max: 60, specialValue: [9999] },
  ELEVASI_STATION: { min: 0, max: 9999, specialValue: [9999] },
  AZIMUT: { min: 0, max: 360, specialValue: [9999] },
  ELEVASI: { min: 0, max: 90, specialValue: [9999] },
}
export function GC_PIBAL(key) {
  let status = false;
  let message = "";
  let min = GC[key].min;
  let max = GC[key].max;
  let spv = GC[key].specialValue;
  status = false;
  message = "Gross Check - Batas Bawah " + min + ", Batas Atas " + max + ", Special Value " + spv;
  return { status: status, message: message };
}

export function RC_PIBAL_AZIMUT(value) {
  let status = false
  let message = ""
  if (value >= 10 && value <= 30) {
    status = true
    message = ""
  }
  else {
    status = false
    message = "min 10, max 30"
  }
  
  return {status: status, message: message}
}