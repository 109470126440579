
import axios from "axios";
import helper from "./helper";

const urldigi = "https://bmkgsatu.bmkg.go.id/awsdigi"
export default {
  
  getSynopDigi(wmoid, tanggal, jam) {
    const get_url = `${urldigi}/synop/${wmoid}?ts=${tanggal}T${jam}:00:00.000Z`;
    return axios.get(get_url, helper.getConfig());
  },

  getIdStationDigi(wmoid) {
    const get_url = `${urldigi}/station/${wmoid}`;
    return axios.get(get_url, helper.getConfig());
  }
}